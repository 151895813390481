import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CampaignsList from './routes/CampaignsList';
import ParticipantsList from './routes/ParticipantsList';
import TicketsList from './routes/TicketsList';
import Dashboard from './routes/Dashboard';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App content={Dashboard} route="dashboard"></App>,
  },
  {
    path: "/campaigns",
    element: <App content={CampaignsList} route="campaigns-list"></App>,
  },
  {
    path: "/participants",
    element: <App content={ParticipantsList} route="participants-list"></App>,
  },
  {
    path: "/tickets",
    element: <App content={TicketsList} route="tickets-list"></App>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
