import { Col, Row, Select, Space, Spin, message } from "antd";
import PageCard from "../components/PageCard";
import { useState } from "react";
import { Pie, Funnel, Area } from '@ant-design/plots';


const PieChart = ({data, title}) => {
    data = data.map((o => {
        return {
            type: o.name,
            value: o.value,
        }
    }));
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        legend: false,
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return (
        <div>
            <h1>{title}</h1>
            <Pie {...config} />
        </div>
    )
}

const ParticipantsFunnel = ({data, title}) => {
    const config = {
        data: data,
        xField: 'name',
        yField: 'value',
        padding: 50,
        legend: false,
        isTransposed: true,
    };
    return (
        <div>
            <h1>{title}</h1>
            <Funnel {...config} />
        </div>
    )
};

const ParticipantsByDate = ({data, title}) => {
    const config = {
        data,
        xField: 'name',
        yField: 'value',
        xAxis: {
          range: [0, 1],
        },
      };

      return (
        <div>
            <h1>{title}</h1>
            <Area {...config} />
        </div>
      )
}


const Dashboard = ({client}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [campaigns, setCampaigns] = useState({loading: false, data: null, selectedID: null});
    const [stats, setStats] = useState({loading: false, data: null});

    if (!campaigns.loading && campaigns.data === null) {
        setCampaigns({loading:true, data: null, selectedID: null});
        client.getCampaigns(
            // {disabled: "false"},
            {},
            (data) => {
                let selectedID = campaigns.selectedID;
                if (selectedID === null && data.campaigns.length > 0) {
                    selectedID = data.campaigns[0].id;
                }
                setCampaigns({loading: false, data: data.campaigns, selectedID: selectedID})
            },
            () => {
                messageApi.open({
                    type: 'error',
                    content: 'Failed to load',
                });
            },
        );
    }

    if (!stats.loading && stats.data === null && campaigns.selectedID !== null) {
        setStats({loading: true, data: null})
        client.getCampaignStats(
            campaigns.selectedID,
            (data) => {
                setStats({loading: false, data: data})
            },
            () => {
                messageApi.open({
                    type: 'error',
                    content: 'Failed to load',
                });
            },
        );
    }

    if (campaigns.loading || stats.loading || stats.data === null || campaigns.data === null) {
        return (
            <PageCard title="Dashboard">
                {contextHolder}
                <Row justify="space-around" align="middle" style={{minHeight: '70vh'}}>
                    <Spin size="large"/>
                </Row>
            </PageCard>
        )
    }
    let updated
    if (stats.data.updated) {
        updated = new Date(stats.data.updated * 1000);
        updated = updated.toLocaleString();
    }

    return (
        <PageCard
            title={
                (
                    <Row>
                        <Col span={12}>
                            <h1 style={{display: "inline-block"}}>Dashboard</h1>
                            <p style={{
                                display: "inline-block",
                                marginLeft: "5px",
                                fontSize:"10px",
                                color:"gray"
                            }}>
                                last updated {updated}
                            </p>
                        </Col>
                        <Col span={12} align="right">
                            <Space wrap style={{marginTop: "10px"}}>
                                <Select
                                    defaultValue={campaigns.selectedID}
                                    style={{ width: 120 }}
                                    disabled={campaigns.data.length < 2}
                                    onChange={val => {
                                        setCampaigns({...campaigns, selectedID: val});
                                        setStats({loading: false, data: null});
                                    }}
                                    options={
                                        campaigns.data.map(c => {
                                            return {value: c.id, label: c.name}
                                        })
                                    }
                                />

                            </Space>
                        </Col>
                    </Row>
                )
            }
        >
            {contextHolder}
            <Row>
                <Col span={24}>
                    <ParticipantsByDate data={stats.data.participants_by_date || []} title="Participants by date"/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ParticipantsFunnel data={stats.data.referrals_funnel || []} title="Participants funnel"/>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <PieChart data={stats.data.tickets_by_status || []} title="Tickets by status"/>
                </Col>
                <Col span={12}>
                    <PieChart data={stats.data.tickets_by_type || []} title="Tickets by type"/>
                </Col>
            </Row>
        </PageCard>
    )
};

export default Dashboard;
