import { Button, Card, Descriptions, Drawer, Form, Input, Popover, Space, Tag, message } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import ObjectsTable from "../components/ObjectsTable";
import ConditionsCards from "../components/ConditionsCards";
import { useState } from "react";

const StatusTag = ({status}) => {
    var color ;
    switch (status) {
        case "pending":
            color = "blue";
            break;
        case "ready":
            color = "yellow";
            break;
        case "collected":
            color = "cyan";
            break;
        case "sent":
            color = "green";
            break;
        default:
    }
    return <Tag color={color}>{status}</Tag>
}

const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Account',
      dataIndex: 'account_id',
      key: 'account_id',
    },
    {
      title: "Type",
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Campaign',
      dataIndex: ['campaign', 'name'],
      key: 'campaign.name',
    },
    {
      title: () => {
        const content = (
            <div>
              <p>pending - conditions aren't fullfilled</p>
              <p>ready - waiting for user to claim the reward</p>
              <p>collected - user collected reward</p>
              <p>sent - admin received reward info</p>
            </div>
        );
        return (
            <Space>
                Status
                  <Popover content={content}>
                    <QuestionCircleOutlined />
                </Popover>
            </Space>
        )
      },
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return <StatusTag status={status}/>
      }
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: (created) => {
            var date = new Date(created * 1000);
            return date.toLocaleString();
        },
    },
]

const TicketRewards = ({rewards}) => {
    if (!rewards) {
        return  <Space></Space>;
    }
    return (
        <Space wrap>
            {
                rewards.map((rew, idx) => {
                    if (rew.type === "static") {
                        return (
                            <Card key={idx} title="Static" size='small'>
                                <p>Amount: {rew.item.amount}</p>
                                <p>Name: {rew.item.name}</p>
                                <p>{rew.collected ? "Yes" : "No"}</p>
                            </Card>
                        )
                    } else {
                        return (
                            <Card key={idx} title="Dynamic" size='small'>
                                <p>Amount: {rew.collected && rew.amount ? rew.amount : "?"}</p>
                                <p>Name: {rew.collected && rew.name ? rew.name : "?"}</p>
                                <p>Collected: {rew.collected ? "Yes" : "No"}</p>
                            </Card>
                        )
                    }
                })
            }
        </Space>
    )
};

const ConditionsCheckItem = ({conditions, loading, loadFunc}) => {
        if (!conditions) {
             return (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={loadFunc}
                >
                    Load
                </Button>
            );
        }

        return (
            conditions.map((c) => {
                if (c.error) {
                    return (
                        <Space>
                            <Popover content={c.error}>
                                <Tag key={c.id} color="red">{c.name}</Tag>
                            </Popover>
                        </Space>
                    );
                }

                let color;
                if (c.fullfiled) {
                    color = "green";
                } else if (c.possible) {
                    color = "yellow";
                } else {
                    color = "";
                }
                return (
                    <Tag key={c.id} color={color}>{c.name}</Tag>
                )
            })
        );
}

const ConditionsStatusLabel = () => {
    const content = (
        <div>
            <p><Tag color="green">Fullfiled</Tag></p>
            <p><Tag color="yellow">Possible</Tag></p>
            <p><Tag color="">Impossible</Tag></p>
            <p><Tag color="red">Error</Tag></p>
        </div>
    );
    return (
        <Space>
            Conditions Status
                <Popover content={content}>
                <QuestionCircleOutlined />
            </Popover>
        </Space>
    )
}

const TicketDetails = ({client, open, onClose, obj}) => {
    const [conditions, setConditions] = useState({
        loading: false,
        data: null,
    });
    const [messageApi, contextHolder] = message.useMessage();

    let description;
    if (obj !== null) {
        description = (
            <Descriptions column={1} bordered>
                {contextHolder}
                <Descriptions.Item label="ID">{obj.id}</Descriptions.Item>
                <Descriptions.Item label="Account">{obj.account_id}</Descriptions.Item>
                <Descriptions.Item label="Status">
                    <StatusTag status={obj.status}/>
                </Descriptions.Item>
                <Descriptions.Item label="Network">{obj.network}</Descriptions.Item>
                <Descriptions.Item label="Campaign">{obj.campaign.name}</Descriptions.Item>
                <Descriptions.Item label="Project">{obj.campaign.project_id}</Descriptions.Item>
                <Descriptions.Item label="Second account">{obj.second_account_id}</Descriptions.Item>
                <Descriptions.Item label="Conditions">
                    <ConditionsCards conditions={obj.conditions}/>
                </Descriptions.Item>
                <Descriptions.Item label="Rewards">
                    <TicketRewards rewards={obj.rewards}/>
                </Descriptions.Item>
                <Descriptions.Item label={<ConditionsStatusLabel/>}>
                    <ConditionsCheckItem
                        conditions={conditions.data}
                        loading={conditions.loading}
                        loadFunc={() => {
                            client.getTicketConditionStatus(
                                obj.id,
                                (resp) => {
                                    setConditions({loading: false, data: resp.conditions})
                                },
                                () => {
                                    messageApi.open({
                                        type: 'error',
                                        content: 'Failed to load',
                                    });
                                    setConditions({...conditions, loading: false})
                                }
                            );
                            setConditions({...conditions, loading: true});
                        }}
                    />
                </Descriptions.Item>
            </Descriptions>
        );
    }
    return (
        <Drawer
            title="Ticket"
            placement={window.innerHeight>window.innerWidth ? "bottom" : "right"}
            closable={true}
            onClose={() => {
                setConditions({...conditions, loading:false, data:null});
                onClose();
            }}
            open={open}
            height="90vh"
            width="50vw"
        >
            {contextHolder}
            {description}
        </Drawer>
    );
};

const TicketsList = (props) => {

    return (
        <ObjectsTable
            title="Tickets"
            columns={columns}
            DetailsCard={TicketDetails}
            client={props.client}
            formFields={(
                <Space>
                    <Form.Item name="account_id">
                        <Input placeholder="Account"></Input>
                    </Form.Item>
                    <Form.Item name="id">
                        <Input placeholder="ID"></Input>
                    </Form.Item>
                </Space>
            )}
            getMethod={(query, success, error) => {
                props.client.getTickets(query, (data) => {success(data.tickets)}, error)
            }}
        />
    )
};

export default TicketsList;
