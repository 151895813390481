import { TeamOutlined, DashboardOutlined, GiftOutlined, LogoutOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Menu, Layout } from 'antd';
import { useState } from 'react';
import { Link } from "react-router-dom";

const { Sider } = Layout;

function getItem(label, key, path, icon, children) {
    return {
        key: key,
        icon: icon,
        children: children,
        label:  <Link to={path}>{label}</Link>,
    };
}

const Sidebar = ({current, logoutFunc}) => {
    const [collapsed, setCollapsed] = useState(window.innerHeight>window.innerWidth);
    const items = [
        getItem('Dashboard', 'dashboard', '/',<DashboardOutlined />),
        getItem('Campaigns', 'campaigns-list', '/campaigns', <UnorderedListOutlined />),
        getItem('Participants', 'participants-list', '/participants', <TeamOutlined />),
        getItem('Tickets', 'tickets-list', '/tickets', <GiftOutlined />),

        {type: 'divider', dashed: true},
        {
            key: "logout",
            icon: <LogoutOutlined />,
            label: <a href="/" onClick={logoutFunc}>Logout</a>,
        },
    ];

    return (
        <Sider
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            collapsible
        >
            <Menu
                theme="dark"
                selectedKeys={[current]}
                mode="inline"
                items={items}
            />
        </Sider>
    )
}

export default Sidebar;
