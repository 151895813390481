import React from 'react';
import { Button, Form, Input, Layout, message, Row, theme } from 'antd';
import APIClient from '../client/Client';



export default function Login(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const {
        token: { colorBgContainer },
      } = theme.useToken();

    const onFinish = (values) => {
        const cli = new APIClient()
        cli.login(values.username, values.password, (data) => { props.setToken(data.token)}, () => {
            messageApi.open({
                type: 'error',
                content: 'Invalid username or password',
            });
        })
    }

    return (
        <Layout style={{background: colorBgContainer}}>
            <Row justify="space-around" align="middle" style={{minHeight: '100vh'}}>
                {contextHolder}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                >
                    <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                    <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    </Form.Item>
                </Form>
            </Row>
        </Layout>
    )
}
