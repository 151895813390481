import { Descriptions, Drawer } from 'antd';
import ObjectsTable from '../components/ObjectsTable';
import ConditionsCards from '../components/ConditionsCards';
import RewardsConfigBlock from '../components/RewardsConfigBlock';

const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Network',
      dataIndex: 'network',
      key: 'network',
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: (created) => {
            var date = new Date(created * 1000);
            return date.toLocaleString();
        },
    },
]


const CampaignDetails = ({open, onClose, obj}) => {
    let description;
    if (obj !== null) {
        description = (
            <Descriptions column={1} bordered>
                <Descriptions.Item label="ID" >{obj.id}</Descriptions.Item>
                <Descriptions.Item label="Name">{obj.name}</Descriptions.Item>
                <Descriptions.Item label="Project">{obj.project}</Descriptions.Item>
                <Descriptions.Item label="Network">{obj.network}</Descriptions.Item>
                <Descriptions.Item label="Auth type">{obj.auth_type || '-'}</Descriptions.Item>
                <Descriptions.Item label="Conditions" id="conditions">
                    <ConditionsCards conditions={obj.conditions}/>
                </Descriptions.Item>
                <Descriptions.Item label="Rewards" id="rewards">
                    <RewardsConfigBlock rewards={obj.rewards}/>
                </Descriptions.Item>
                    {
                        Object.entries(obj.custom_rewards || {}).map(([k, v])=> {
                            return (
                                <Descriptions.Item key={"rewards"+k} label={k.charAt(0).toUpperCase() + k.slice(1) + " rewards"}>
                                    <RewardsConfigBlock rewards={v}></RewardsConfigBlock>
                                </Descriptions.Item>
                            )
                        })
                    }
                <Descriptions.Item label="Active">{obj.disabled ? 'Disabled': 'Active' }</Descriptions.Item>
            </Descriptions>
        );
    }
    return (
        <Drawer
            title="Campaign"
            placement={window.innerHeight>window.innerWidth ? "bottom" : "right"}
            closable={true}
            onClose={onClose}
            open={open}
            height="90vh"
            width="50vw"
        >
            {description}
        </Drawer>
    );
};

const CampaignsList = (props) => {
    return (
        <ObjectsTable
            title="Campaigns"
            columns={columns}
            DetailsCard={CampaignDetails}
            getMethod={(query, success, error) => {
                props.client.getCampaigns({}, (data) => {success(data.campaigns)}, error)
            }}
        />
    )
};

export default CampaignsList;
