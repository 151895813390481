import { Card, Space } from "antd"

const RewardsConfigBlock = ({rewards}) => {
    if (!rewards) {
        return  <Space></Space>;
    }
    return (
        <Space wrap>
            {
                rewards.map((rew, idx) => {
                    if (rew.type === "static") {
                        return (
                            <Card key={idx} title="Static" size='small'>
                                <p>{rew.item.amount}</p>
                                <p>{rew.item.name}</p>
                            </Card>
                        )
                    } else {
                        return (
                            <Card key={idx} title="Dynamic" size='small'>
                                <p>Mean: {rew.target_mean}</p>
                                <p>Min: {rew.min_amount}</p>
                                <p>Max: {rew.max_amount}</p>
                            </Card>
                        )
                    }
                })
            }
        </Space>
    )
};

export default RewardsConfigBlock;
