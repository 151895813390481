export const GetTelegramLink = (username) => {
    const prefix = 'https://t.me/'
    if (username.startsWith('@')) {
        username  = username.substring(1)
    }
    return prefix + username
}

export const GetTwitterLink = (username) => {
    const prefix = 'https://twitter.com/'
    if (username.startsWith('@')) {
        username  = username.substring(1)
    }
    return prefix + username
}
