
export default class APIClient {
    constructor(token, logoutFunc) {
        this.token = token;
        this.logoutFunc = logoutFunc
        this.url = window.location.origin;
        // this.url = 'http://localhost:8080';
    }

    login(username, password, success, error) {
        fetch(this.url + "/api/v1/backoffice/login", {
            method: "POST",
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        }).then((response) =>{
            if (response.status === 200) {
                response.json().then((data) => {
                    success(data.data);
                });
            } else {
                error(response);
            }
        }).catch(() => {
            error();
        })
    }

    getHeaders() {
        return {
            "Authorization": "Bearer " + this.token,
        }
    }

    makeRequest(req, success, error) {
        req.then((response) =>{
            if (response.status === 200) {
                response.json().then((data) => {
                    success(data.data);
                });
            } else {
                if (response.status === 401) {
                    this.logoutFunc();
                    return;
                }
                error(response);
            }
        }).catch(() => {
            error();
        })
    }

    getCampaigns(query, success, error) {
        let url = new URL(this.url + "/api/v1/campaign/search");
        Object.entries(query).forEach(([k, v]) => {
            if (v) {
                url.searchParams.append(k, v);
            }
        })
        this.makeRequest(
            fetch(url, {
                method: "GET",
                headers: this.getHeaders(),
            }),
            success,
            error,
        )
    }

    getCampaignStats(campaignID, success, error) {
        let url = new URL(this.url + `/api/v1/campaign/${campaignID}/stats`);
        this.makeRequest(
            fetch(url, {
                method: "GET",
                headers: this.getHeaders(),
            }),
            success,
            error,
        )
    }

    getParticipants(query, success, error) {
        let url = new URL(this.url + "/api/v1/participant/search");
        Object.entries(query).forEach(([k, v]) => {
            if (v) {
                url.searchParams.append(k, v);
            }
        })
        this.makeRequest(
            fetch(url, {
                method: "GET",
                headers: this.getHeaders(),
            }),
            success,
            error,
        )
    }

    getTickets(query, success, error) {
        let url = new URL(this.url + "/api/v1/tickets/search");
        Object.entries(query).forEach(([k, v]) => {
            if (v) {
                url.searchParams.append(k, v);
            }
        })
        this.makeRequest(
            fetch(url, {
                method: "GET",
                headers: this.getHeaders(),
            }),
            success,
            error
        )
    }

    getTicketConditionStatus(ticketID, success, error) {
        this.makeRequest(
            fetch(this.url + `/api/v1/tickets/${ticketID}/conditions-status`, {
                method: "GET",
                headers: this.getHeaders(),
            }),
            success,
            error
        )
    }
};
