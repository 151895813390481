import { Card } from "antd"

const PageCard = (props) => {
    return (
        <Card title={props.title} bordered={true} style={{marginTop: '10px'}}>
            {props.children}
        </Card>
    )
}

export default PageCard
