import { Card, Space, Tag } from "antd";
import { TwitterOutlined} from '@ant-design/icons';
import formatDuration from 'format-duration';
import { TelegramIcon } from "./icons";
import { GetTelegramLink, GetTwitterLink } from "../utils/socialsLinks";

const ConditionsCards = ({conditions}) => {
    return (
        <Space>
            {
                conditions.map((c, idx) => {
                    const items = []
                    if (c.stake_amount) {
                        items.push(["Stake amount", c.stake_amount]);
                    }
                    if (c.stake_duration) {
                        items.push(["Stake duration", formatDuration(c.stake_duration * 1000)]);
                    }
                    if (c.farming_amount) {
                        items.push(["Farming amount", c.farming_amount]);
                    }
                    if (c.farming_duration) {
                        items.push(["Farming duration", formatDuration(c.farming_duration * 1000)]);
                    }
                    if (c.socials) {
                        items.push(["Socials", c.socials.join(", ")]);
                    }
                    if (c.socials_subscriptions) {
                        items.push(["Social subscriptions", c.socials_subscriptions.map((s, idx) => {
                            let icon;
                            let link;
                            if (s.type === "telegram")  {
                                    link = GetTelegramLink(s.name)
                                    icon = <TelegramIcon/>;
                            } else if (s.type === "twitter") {
                                    link = GetTwitterLink(s.name)
                                    icon = <TwitterOutlined />;
                            }
                            if (link) {
                                return (
                                    <a href={link} key={idx} target="_blank" rel="noreferrer">
                                        <Tag id={s.type} icon={icon}>{s.name}</Tag>
                                    </a>
                                );
                            } else {
                                return (
                                    <Tag key={idx} id={s.type} icon={icon}>{s.name}</Tag>
                                );
                            }
                        })]);
                    }
                        return <Card key={idx}>
                        {
                            items.map((i, idx) => {
                                return <p key={"cond_"+idx}>{i[0]}: {i[1]}</p>
                            })
                        }
                    </Card>
                })
            }
        </Space>
    )
}

export default ConditionsCards;
