import { Layout } from 'antd';
import Sidebar from './sidebar/Sidebar';
import React from 'react';
import APIClient from './client/Client';
import Login from './routes/Login';

const { Content } = Layout;

export default class App extends React.Component {
    constructor() {
        super();
        this.state = {
            token: window.localStorage.getItem('authtoken'),
        }
    }

    setState(state) {
        if (state["token"]) {
            window.localStorage.setItem('authtoken', state["token"]);
        }
        super.setState(state);
    }
    logout() {
        window.localStorage.removeItem('authtoken');
        this.setState({"token": null})
    }

    render() {
        if (this.state.token === null) {
            return <Login setToken={(token)=> { this.setState({"token": token}) }}/>;
        }

        const client = new APIClient(
            this.state.token,
            () => {this.logout()},
        )

        return (
            <Layout style={{maxHeight: "100vh"}}>
                <Sidebar current={this.props.route} logoutFunc={() => {this.logout()}}/>
                <Layout style={{
                    overflow:"auto",
                    minHeight: "100vh",
                }}>
                    <Content
                        style={{
                            margin: '0 16px',
                        }}
                    >
                        <this.props.content
                            client={client}
                        />
                    </Content>
                </Layout>
            </Layout>
        );
    }

};
